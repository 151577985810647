<template>
	<div class="main-contents">
		<div class="tit" >문의사항 관리<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/></div>
		<div class="sub-tit" style="margin-bottom: 65px;"><hr><p>문의사항 관리</p><hr></div>
		<div class="contents-body demand">
			<div class="prj_outline">
				<div class="date">
					<div class="label ivl02">등 록 일</div><div class="value">{{inquiryMattDetail.regDate | date('yyyy.MM.dd')}}</div>
				</div>
				<div class="name">
					<div class="label ivl03" style="margin-right: 22px; vertical-align: top;">제목</div><div class="value name" style="width: 900px;">{{inquiryMattDetail.title}}</div>
				</div>
				<div class="outline">
					<div class="label" style="vertical-align: top;">문 의 내 용</div>
					<div class="value busi_inform" style="padding: 0; margin-top: 0; width: 900px;" v-html="inquiryMattDetail.inquiryCont"></div>
				</div>
			</div>
			<div class="prj_outline last">
				<div class="outline">
					<div class="label">이메일주소</div><div class="value">{{inquiryMattDetail.email}}</div>
				</div>
				<div class="outline">
					<div class="label ivl02">연 락 처</div><div class="value">{{inquiryMattDetail.telNo | phone}}</div>
				</div>
			</div>
		</div>

		<div class="list matter">
			<textarea class="contents-body demand" style="height: 220px; font-size: 16px; line-height: 25px; font-family: NanumSquare_acR;" v-model="inquiryMattDetail.answerCont" placeholder="답 변 내 용"></textarea>
		</div>
	
		<div class="prj_outline last" style="width: 1275px;" v-if="modLoginId != ''" >
			<div class="label"><span class="ivl02">답변 담당 : </span>{{modLoginId}}</div>
			<span class="select_btn" >
				<div class="btn_02" style="float: right; background-color: #FF681D;" @click="setUpdateInquiryMattDetail()">수정</div>
			</span>
		</div>
		<div class="prj_outline last" style="width: 1275px;" v-else>
			<span class="select_btn" >
				<div class="btn_02" style="float: right;" @click="setUpdateInquiryMattDetail()">저장</div>
			</span>
		</div>

	</div>
</template>

<script>
export default {
	data() {
		return{
			inquiryMattDetail: {},

			inquiryMattSeq : this.$route.params.inquiryMattSeq || '',
			modLoginId : this.$route.params.modLoginId || ''
		}
	},
	
	beforeMount(){
		this.getInquiryMattDetail();
	},

	methods : {
		// 문의사항 상세 조회
		getInquiryMattDetail(){
			this.$.httpPost('/api/main/adm/cts/getInquiryMattDetail', {inquiryMattSeq : this.inquiryMattSeq})
				.then(res => {
					this.inquiryMattDetail = res.data.inquiryMattDetail;
				}).catch(this.$.httpErrorCommon);
		},

		// 문의사항 답변 등록/수정
		setUpdateInquiryMattDetail(){
			this.$.popup('/adm/cts/CTS901P01')
				.then(res=>{
					if(res){
						this.$.httpPost('/api/main/adm/cts/setUpdateInquiryMattDetail', this.inquiryMattDetail)
						.then(() => {
							this.movePage();
						}).catch(this.$.httpErrorCommon);
					}
				})
		},

		movePage(div) {
        if(div == 'back') this.$router.go(-1);
        else this.$router.push('CTS901M01');
        }
	}
}
</script>